import React, { Component } from "react";
import { Card, Button, Select, Modal, Skeleton, message } from "antd";
import * as Scroll from "react-scroll";
import debounce from "lodash.debounce";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";

import ImagesComponent from "./ImagesComponent";
import CanvasComponent from "./CanvasComponent";

import update from "immutability-helper";
import RequestServices from "../helpers/requestServices";

import styles from "./Hotspots.module.scss";
import camera from "../assets/img/camera.svg";
import cameraActive from "../assets/img/camera-active.svg";

const { Option } = Select;
let scroll = Scroll.scroller;

interface Props {}
interface State {
  plan: HTMLImageElement | undefined;
  icon: any;
  scale: any;
  iconImg: any;
  position: any;
  hotspots: { markers: [] }[];
  images: any[];
  baseUrl: string;
  stage: number;
  stageId: number;
  dropAll: boolean;
  activeSpots: number | undefined;
  visibleModal: boolean;
  lastSaveTime: number;
}
const api = new RequestServices({});
let img: any = null;
let iconImg: any = null;
let iconImgActive: any = null;

const UID_ORDER = window.location.pathname.split("/")[2];
const save = (cb: any) => {
  cb();
};
const debounceSave = debounce(save, 5000);

const skeletonImg = [
  <Skeleton active={true} key={1} />,
  <Skeleton active={true} key={2} />,
  <Skeleton active={true} key={3} />,
];

export default class HotspotsComponent extends Component<Props, any> {
  state = {
    plan: undefined,
    scale: {
      x: 0.8,
      y: 0.8,
    },
    iconImg: undefined,
    iconImgActive: undefined,
    icon: [],
    position: {
      x: 0,
      y: 0,
    },
    hotspots: [
      {
        markers: [{}],
        stageImage: "",
        name: "Stage",
        hs_id: 0,
      },
    ],
    images: [{}],
    baseUrl: "",
    stage: 0,
    stageId: 0,
    activeSpots: undefined,
    dropAll: false,
    visibleModal: false,
    fullScreen: false,
    resize: true,
    loadingSave: false,
    loading: true,
    lastSaveTime: 0,
  };

  planRef: any;
  stage: any;
  stageWrapper: any;
  componentDidMount = () => {
    document.title = "Edit Photos on FloorPlans"
    this.loadData();
    window.addEventListener("keyup", this.isDropAll);
    window.addEventListener("keyup", this.deleteAllSpotsEvent);
    window.addEventListener("resize", () => {
      this.setState({
        resize: !this.state.resize,
      });
    });
  };

  isDropAll = (e: any) => {
    if (e.ctrlKey && e.shiftKey) {
      if (e.code === "KeyA") {
        console.log("eeeeeeeeeeeeee");
        this.setState({
          dropAll: true,
        });
      }
    }
  };

  deleteAllSpotsEvent = (e: any) => {
    if (e.shiftKey) {
      if (e.keyCode === 46) {
        this.setState({
          visibleModal: true,
        });
      }
    }
  };

  deleteAll = () => {
    const newArr = this.state.hotspots[this.state.stage].markers.map(
      (el: any) => {
        return {
          ...el,
          hs_id: 0,
        };
      }
    );
    const newImageArr = this.state.images.map((el: any) => {
      if (el.hs_id !== this.state.stageId) {
        return el;
      }
      return {
        ...el,
        hs_id: 0,
      };
    });
    const newState = update(this.state.hotspots, {
      [this.state.stage]: {
        markers: {
          $set: [...newArr] as any,
        },
      },
    });
    this.setState({
      hotspots: newState,
      visibleModal: false,
      images: newImageArr,
    });
  };

  hideModal = () => {
    this.setState({
      visibleModal: false,
    });
  };

  dropAllOff = () => {
    this.setState({
      dropAll: false,
    });
  };

  // setScale = (scale: any) => {
  //   console.log(scale)
  //   this.setState(() => {
  //     return{
  //     scale: {
  //       x: scale,
  //       y: scale
  //     }}
  //   })
  // }

  loadData = async () => {
    try {
      const { data } = await api.getHotspots(UID_ORDER);
      // console.log("dsadasd", data);
      let markers: any = [];
      data.data.hotspots.forEach((e: any) => {
        markers = [...markers, ...e.markers];
      });
      const images = data.data.images.items.map((elImg: any) => {
        // let hsId = 0
        const savedIcon = markers.findIndex((el: any) => {
          return el.file_name === elImg.file_name;
        });
        if (savedIcon !== -1) {
          return {
            ...elImg,
            hs_id: markers[savedIcon].hs_id,
          };
        } else {
          return {
            ...elImg,
            hs_id: 0,
          };
        }
      });
      this.setState({
        baseUrl: data.data.base_path,
        hotspots: data.data.hotspots,
        images: images,
        stageId: data.data.hotspots[0].hs_id,
        lastSaveTime: data.data.last_updated_date.timestamp,
      });
      this.loadImg();
    } catch (error) {
      console.log(error.response.data.errors.detail);
      message.error(error.response.data.errors.detail)
    }
  };

  setActiveSpots = (id: number) => {
    this.setState({
      activeSpots: id,
    });
    scroll.scrollTo(id.toString(), {
      containerId: "imgWrapper",
      duration: 1500,
      delay: 100,
      smooth: true,
    });
  };

  saveHotspots = async () => {
    this.setState({
      loadingSave: true,
    });
    let markers: any = [];
    this.state.hotspots.forEach((e) => {
      markers = [...markers, ...e.markers];
    });
    const data = markers.map((e: any) => {
      return {
        [e.hsi_id]: {
          coord_x: e.coord_x,
          coord_y: e.coord_y,
          hs_id: e.hs_id,
          pan_angle: 0,
          begining: e.beginning,
        },
      };
    });
    let dataObj = {};
    data.forEach((el: any) => {
      dataObj = {
        ...dataObj,
        ...el,
      };
    });
    try {
      await api.saveHotspots(UID_ORDER, dataObj);
      this.setState({
        loadingSave: false,
        lastSaveTime: Date.now() / 1000,
      });
    } catch (error) {
      this.setState({
        loadingSave: true,
      });
    }
  };

  loadImg = (e?: any) => {
    const stage = e === undefined ? 0 : e;
    img = new window.Image();
    iconImg = new window.Image(32, 32);
    iconImgActive = new window.Image(32, 32);

    img.src = `${this.state.baseUrl}${
      this.state.hotspots[stage]["stageImage"]
    }`;
    iconImg.src = camera;
    iconImg.type = 'image/svg+xml'
    iconImgActive.src = cameraActive;

    iconImgActive.addEventListener("load", this.handleLoadIconActive);
    iconImg.addEventListener("load", this.handleLoadIcon);
    img.addEventListener("load", this.handleLoad);
  };

  handleLoad = () => {
    this.setState(() => {
      return {
        plan: img,
        loading: false,
      };
    });
  };

  handleLoadIcon = () => {
    this.setState(() => {
      return { iconImg: iconImg };
    });
  };

  handleLoadIconActive = () => {
    this.setState({
      iconImg: iconImgActive,
    });
  };

  zoom = (event: any) => {
    const { x, y } = this.state.scale;
    this.setState({
      scale: {
        x: x + event.evt.deltaY * -0.001,
        y: y + event.evt.deltaY * -0.001,
      },
    });
  };

  deleteIcon = (id: number) => {
    // console.log(index)
    let newImageState: any = [];
    const index: any = this.state.hotspots[this.state.stage].markers.findIndex(
      (e: any) => {
        return e.hsi_id === +id;
      }
    );
    if (index === -1) {
      return false;
    }
    const indexImg = this.state.images.findIndex((el: any) => {
      return el.hsi_id === id;
    });
    if (index !== -1) {
      newImageState = update(this.state.images, {
        $splice: [
          [
            indexImg,
            1,
            {
              ...this.state.images[indexImg],
              hs_id: 0,
              begining: 0
            },
          ] as any,
        ],
      });
      this.setState({
        images: newImageState,
      });
    }
    const newState = update(this.state.hotspots, {
      [this.state.stage]: {
        markers: {
          $splice: [
            [
              index,
              1,
              {
                ...this.state.hotspots[this.state.stage].markers[index],
                hs_id: 0,
                beginning: 0
              },
            ],
          ] as any,
        },
      },
    });
    this.setState({
      hotspots: newState,
    });
    debounceSave(this.saveHotspots);
  };

  editIcon = (index: number, position: any) => {
    const newState = update(this.state.hotspots, {
      [this.state.stage]: {
        markers: {
          $splice: [
            [
              index,
              1,
              {
                ...this.state.hotspots[this.state.stage].markers[index],
                coord_x: position.x,
                coord_y: position.y,
              },
            ],
          ] as any,
        },
      },
    });
    this.setState(
      {
        hotspots: newState,
      },
      () => debounceSave(this.saveHotspots)
    );
  };

  windowFullscreen = () => {
    const documentFull: any = document;
    const isInFullScreen =
      (documentFull.fullscreenElement &&
        documentFull.fullscreenElement !== null) ||
      (documentFull.webkitFullscreenElement &&
        documentFull.webkitFullscreenElement !== null) ||
      (documentFull.mozFullScreenElement &&
        documentFull.mozFullScreenElement !== null) ||
      (documentFull.msFullscreenElement &&
        documentFull.msFullscreenElement !== null);

    const docElm: any = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (documentFull.exitFullscreen) {
        documentFull.exitFullscreen();
      } else if (documentFull.webkitExitFullscreen) {
        documentFull.webkitExitFullscreen();
      } else if (documentFull.mozCancelFullScreen) {
        documentFull.mozCancelFullScreen();
      } else if (documentFull.msExitFullscreen) {
        documentFull.msExitFullscreen();
      }
    }
    this.setState({
      fullScreen: !this.state.fullScreen,
    });
  };

  lastSaveCalc = () => {
    const { lastSaveTime } = this.state;
    if (lastSaveTime === 0 || !lastSaveTime) {
      return "";
    }
    const min = Math.round((Date.now() / 1000 - lastSaveTime) / 60);
    const hour = Math.round(min / 60);
    const day = Math.round(hour / 24);
    // ${Math.round((Date.now() / 1000 - lastSaveTime) / 60)}
    if (window.innerWidth < 1200) {
      return "Saved";
    }
    if (day > 0) {
      return `Last saved ${day} day ago`;
    }
    if (hour > 0) {
      return `Last saved ${hour} hour ago`;
    }
    if (min > 0) {
      return `Last saved ${min} min ago`;
    }
    return `Last save less than a minute ago`;
  };

  setBeginingPhoto = (id: any) => {
    let newState: any = [];
    let delState: any = [];
    let newImageState: any = [];
    const { hotspots, stage, stageId } = this.state; 
    const indexSpotsSet = hotspots[stage].markers.findIndex((el: any) => {
      return el.hsi_id === +id;
    });


    const indexSpotsDelete = hotspots[stage].markers.findIndex((el: any) => {
      if (el.beginning === 1 && +stageId === el.hs_id) {
        return true;
      }
      return false;
    });

    if (indexSpotsDelete !== -1) {
      delState = update(hotspots, {
        [stage]: {
          markers: {
            $splice: [
              [
                indexSpotsDelete,
                1,
                {
                  ...hotspots[stage].markers[indexSpotsDelete],
                  beginning: 0,
                },
              ],
            ],
          },
        },
      });
    }

    const updateDelState = indexSpotsDelete !== -1 ? delState : hotspots;

    newState = update(updateDelState, {
      [stage]: {
        markers: {
          $splice: [
            [
              indexSpotsSet,
              1,
              {
                ...hotspots[stage].markers[indexSpotsSet],
                beginning: 1,
              },
            ],
          ],
        },
      },
    });

    // update images state
    const indexImages = this.state.images.findIndex((el: any) => {
      console.log(el)
      if (el.begining === 1 && stageId === el.hs_id) {
        return true;
      }
      return false;
    });

    if (indexImages !== -1) {
      console.log('allo2')
      newImageState = update(this.state.images, {
        $splice: [
          [
            indexImages,
            1,
            {
              ...this.state.images[indexImages],
              begining: 0,
            },
          ] as any,
        ],
      });
    }

    const imageIndexSet = this.state.images.findIndex((el: any) => {
      return el.hsi_id === +id;
    });

    const newImageStateDel =
      indexImages !== -1 ? newImageState : this.state.images;

      newImageState = update(newImageStateDel, {
        $splice: [
          [
            imageIndexSet,
            1,
            {
              ...this.state.images[imageIndexSet],
              begining: 1,
            },
          ] as any,
        ],
      });
      console.log(newImageState)
    this.setState({
      hotspots: newState,
      images: newImageState
    });
    debounceSave(this.saveHotspots);
  };

  dropIcon = (event: any, stage: any, position: any, scale: any) => {
    event.preventDefault()
    stage.setPointersPositions(event);
    let markers: any = [];
    this.state.hotspots.forEach((e) => {
      markers = [...markers, ...e.markers];
    });
    let newState: any = [];
    let newImageState: any = [];
    let newArr: any = [];
    let newImageArr: any = [];
    if (this.state.dropAll) {
      this.state.images.forEach((el: any, i: number) => {
        const findIndex = markers.findIndex((elDrop: any) => {
          return el.file_name === elDrop.file_name;
        });
        if (findIndex !== -1) {
          newImageArr.push({
            ...el,
          });
          return;
        }
        newArr.push({
          hsi_id: el.hsi_id,
          hs_id: this.state.hotspots[this.state.stage].hs_id,
          coord_x: Math.floor(360 / 3 / 2) * Math.random() * 10 + 100,
          coord_y: Math.floor(360 / 3 / 2) * Math.random() * 10 + 50,
          pan_angle: 0,
        });
        newImageArr.push({
          ...el,
          hs_id: this.state.stageId,
        });
      });
      newState = update(this.state.hotspots, {
        [this.state.stage]: {
          markers: {
            $set: [
              ...newArr,
              ...this.state.hotspots[this.state.stage].markers,
            ] as any,
          },
        },
      });
      this.setState({
        images: newImageArr,
      });
    } else {
      const id = event.dataTransfer.getData("Text");
      if (!id) {
        return;
      }
      const indexSpots = this.state.hotspots[
        this.state.stage
      ].markers.findIndex((el: any) => {
        return el.hsi_id === +id;
      });

      if (indexSpots !== -1) {
        newState = update(this.state.hotspots, {
          [this.state.stage]: {
            markers: {
              $splice: [
                [
                  indexSpots,
                  1,
                  {
                    hsi_id: +id,
                    hs_id: this.state.hotspots[this.state.stage].hs_id,
                    coord_x:
                      stage.getPointerPosition().x / scale.x -
                      position.x / scale.x,
                    coord_y:
                      stage.getPointerPosition().y / scale.x -
                      position.y / scale.x,
                    pan_angle: 0,
                  },
                ],
              ],
            },
          },
        });
      } else {
        newState = update(this.state.hotspots, {
          [this.state.stage]: {
            markers: {
              $push: [
                {
                  hsi_id: +id,
                  hs_id: this.state.hotspots[this.state.stage].hs_id,
                  coord_x:
                    stage.getPointerPosition().x / scale.x -
                    position.x / scale.x,
                  coord_y:
                    stage.getPointerPosition().y / scale.x -
                    position.y / scale.x,
                  pan_angle: 0,
                },
              ] as any,
            },
          },
        });
      }

      const index = this.state.images.findIndex((el: any) => {
        return el.hsi_id === +id;
      });
      if (index !== -1) {
        newImageState = update(this.state.images, {
          $splice: [
            [
              index,
              1,
              {
                ...this.state.images[index],
                hs_id: this.state.stageId,
              },
            ] as any,
          ],
        });
      }
      this.setState({
        images: newImageState,
      });
    }
    this.setState({
      hotspots: newState,
    });
    debounceSave(this.saveHotspots);
  };

  render() {
    const getIconLength = () => {
      let markers: any = [];
      let res: number = 0;
      this.state.hotspots.forEach((e) => {
        markers = [...markers, ...e.markers];
      });

      markers.forEach((el: any) => {
        if (el.hs_id !== 0) {
          ++res;
        }
      });
      return res;
    };
    const { plan, scale } = this.state;
    const { width, height }: any = plan ? plan : { width: 0, height: 0 };
    const title = (
      <div>
        <Button
          size="small"
          style={{ padding: "0 5px", marginRight: 10 }}
          onClick={this.windowFullscreen}
        >
          {!this.state.fullScreen ? (
            <FullscreenOutlined />
          ) : (
            <FullscreenExitOutlined />
          )}
        </Button>
        <span>Photos on Floor Plan</span>
        <span style={{ opacity: 0.8, marginLeft: 5 }}>
          ({getIconLength()} of {this.state.images.length} placed)
        </span>
      </div>
    );
    let indexImage = 0;
    return (
      <div
        ref={(node) => {
          this.stageWrapper = node;
        }}
        className={styles.WrapperHotspots}
        style={{ padding: "20px", height: "calc(100vh - 40px)" }}
      >
        <Modal
          title="Delete spots"
          visible={this.state.visibleModal}
          onOk={this.deleteAll}
          onCancel={this.hideModal}
          okText="Delete"
          cancelText="Cancel"
        >
          <p>
            Delete all spots from{" "}
            <b>{this.state.hotspots[this.state.stage].name}</b>?
          </p>
        </Modal>
        <Card
          title={title}
          extra={
            <div className={styles.Extra}>
              {this.state.hotspots.length === 1 ? (
                <span>{this.state.hotspots[this.state.stage].name}</span>
              ) : (
                <div>
                  {/* <Slider style={{width: 150}} min={.5} max={2} defaultValue={.8} step={.2} onChange={(e) => this.setScale(e)} /> */}
                  <span style={{ marginRight: 10 }}>Change Floors: </span>
                  <Select
                    value={this.state.hotspots[this.state.stage].name}
                    style={{ width: 150 }}
                    size="small"
                    onChange={(e: any) => {
                      this.setState({
                        stage: e,
                        stageId: this.state.hotspots[e].hs_id,
                      });
                      Scroll.animateScroll.scrollToTop({
                        containerId: "imgWrapper",
                        duration: 500,
                        delay: 100,
                        smooth: true,
                      })
                      this.loadImg(e);
                    }}
                  >
                    {this.state.hotspots.map((el: any, i: number) => {
                      return (
                        <Option key={el.hs_id} value={i}>
                          {el.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              )}
              <div>
                <span style={{ marginRight: 10, opacity: 0.8 }}>
                  {this.lastSaveCalc()}
                </span>
                <Button
                  style={{ width: 105 }}
                  size="small"
                  type="primary"
                  onClick={this.saveHotspots}
                  loading={this.state.loadingSave}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        >
          <div className={styles.Wrapper}>
            {this.state.loading ? (
              <Skeleton.Avatar
                style={{
                  width: "calc(100vw - 360px)",
                  height: "calc(100vh - 160px)",
                }}
                active={true}
                shape="square"
              />
            ) : (
              <CanvasComponent
                plan={plan}
                iconImg={iconImg}
                activeSpots={this.state.activeSpots}
                iconImgActive={iconImgActive}
                dropIcon={this.dropIcon}
                clientWidth={this.stageWrapper && this.stageWrapper.clientWidth}
                clientHeight={
                  this.stageWrapper && this.stageWrapper.clientHeight
                }
                markers={this.state.hotspots[this.state.stage].markers}
                setActiveSpots={this.setActiveSpots}
                editIcon={this.editIcon}
              />
            )}
            <div
              className={styles.ImagesWrapp}
              style={{
                height:
                  this.stageWrapper && this.stageWrapper.clientHeight - 115,
              }}
              draggable={this.state.dropAll}
              id="imgWrapper"
            >
              {this.state.loading
                ? skeletonImg
                : this.state.images.map((image: any, i: number) => {
                    if (
                      image.hs_id !== this.state.stageId &&
                      image.hs_id !== 0
                    ) {
                      return null;
                    }
                    ++indexImage;
                    return (
                      <div key={image.hsi_id}>
                        <ImagesComponent
                          baseUrl={this.state.baseUrl}
                          fileName={image.file_name}
                          activeSpots={this.state.activeSpots}
                          index={indexImage}
                          hotspots={this.state.hotspots}
                          id={image.hsi_id}
                          dropAll={this.state.dropAll}
                          onDragStart={() => console.log("dsad")}
                          setActiveSpots={this.setActiveSpots}
                          deleteIcon={this.deleteIcon}
                          dropAllOff={this.dropAllOff}
                          begining={image.begining}
                          icon={iconImg}
                          setBeginingPhoto={this.setBeginingPhoto}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
