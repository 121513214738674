import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Modal, Radio, Tooltip } from "antd";
import * as Scroll from "react-scroll";

import styles from "./Hotspots.module.scss";

import cameraPng from "../assets/img/cameraPng.png"

interface Props {
  index: number;
  id: number;
  baseUrl: string;
  fileName: string;
  hotspots: any;
  activeSpots: number | undefined;
  dropAll: boolean;
  begining: any;
  icon: any;
  onDragStart: () => void;
  dropAllOff: () => void;
  setActiveSpots: (id: number) => void;
  deleteIcon: (index: number) => void;
  setBeginingPhoto: (id: any) => void;
}

const img = new Image()

const ImagesComponent = ({
  index,
  id,
  baseUrl,
  fileName,
  hotspots,
  activeSpots,
  dropAll,
  begining,
  icon,
  onDragStart,
  dropAllOff,
  deleteIcon,
  setActiveSpots,
  setBeginingPhoto,
}: Props) => {
  useEffect(() => {
    img.src = cameraPng
  }, [])
  const [opasity, setOpasity] = useState(1);
  const [visible, setVisible] = useState(false);
  const tooltipWrapper = useRef(null) as any;
  const isToAdd = () => {
    let markers: any = [];
    hotspots.forEach((el: any) => {
      markers = [...markers, ...el.markers];
    });
    // console.log("dsa", markers);
    const mark = markers.find((el: any) => {
      return el.hsi_id === id;
    });
    if (mark && mark.hs_id !== 0) {
      return true;
    }
    return false;
  };

  const borderStyle = {
    border:
      activeSpots === id || dropAll
        ? "3px solid #1574BB"
        : "3px solid transparent",
    // boxSizing: "border-box",
    borderRadius: "4px",
  };

  const cover = (
    <div className={styles.ImgCover}>
      <img
        draggable={false}
        src={baseUrl + encodeURIComponent(fileName)}
        alt=""
        onDragStart={(e) => e.preventDefault()}
      />
      <div className={styles.FileNameWrapp}>
        <span className={styles.FileName}>{fileName}</span>
      </div>
    </div>
  );

  return (
    <Scroll.Element name={id.toString()}>
      <div
        ref={tooltipWrapper}
        style={{ padding: "0 15px", position: "relative" }}
      >
        {isToAdd() && (
          <Tooltip
            getPopupContainer={() => tooltipWrapper.current}
            placement="leftTop"
            destroyTooltipOnHide={true}
            title="Set as main photo."
          >
            <Radio
              checked={begining}
              onClick={() => setBeginingPhoto(id)}
              style={{
                position: "absolute",
                right: "15px",
                zIndex: 99,
                top: "5px",
              }}
            />
          </Tooltip>
        )}

        <Card
          className={styles.Images}
          style={borderStyle}
          bodyStyle={{ padding: 0 }}
          hoverable={true}
          draggable={dropAll ? false : !isToAdd()}
          onDoubleClick={() => setVisible(true)}
          onDragStart={(e: any) => {
            e.dataTransfer.effectAllowed = "move";
            e.dataTransfer.setData("Text", id);
            e.dataTransfer.setDragImage(img, 16, 16);
            setOpasity(0);
          }}
          onClick={() => {
            dropAllOff();
            setActiveSpots(id);
          }}
          onDragEnd={() => {
            setOpasity(1);
          }}
          cover={cover}
        >
          <div className={styles.Actions}>
            <Modal
              title={fileName}
              visible={visible}
              footer={null}
              width={700}
              onCancel={() => setVisible(false)}
            >
              <img
                style={{ width: "100%" }}
                src={
                  baseUrl +
                  "orig_" +
                  encodeURIComponent(fileName) +
                  "?auto=compress&w=700"
                }
                alt=""
              />
            </Modal>
            <span>{index}</span>
            <Button
              disabled={!isToAdd()}
              onClick={() => deleteIcon(id)}
              type="link"
            >
              {isToAdd() ? "REMOVE" : "DRAG TO ADD"}
            </Button>
          </div>
          {isToAdd() && <div style={{position: 'absolute', top: 0, left: -1, width: 'calc(100% + 2px)', height: 'calc(100% - 32px)', backgroundColor: 'rgba(165, 165, 165, .7)'}}/>}
        </Card>
      </div>
    </Scroll.Element>
  );
};

export default ImagesComponent;
